import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './DietHome.css';
import {addDiet, createDB, DietValue, putDiet} from "../database";
import Diet from "../components/Diet";
import React from "react";
import DietSummary from "../components/DietSummary";

const DietHome = (props: {
    currentDate: Date | undefined,
    diet: DietValue | undefined,
    dietChanged: (diet: DietValue) => void
}) => {
    const {currentDate, diet, dietChanged} = props;
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Diet</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Diet</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div>
                    {currentDate === undefined || diet === undefined ?
                        <Diet diet={diet} onDietSubmit={((newDiet) => {
                            async function doAdd() {
                                const db = await createDB();
                                const diet = await addDiet(db, newDiet);

                                dietChanged(diet);
                            }

                            doAdd();
                        })}></Diet> :
                        <DietSummary diet={diet}
                                     date={currentDate}
                                     onEndDiet={(dietToEnd) => {
                                         async function doEnd() {
                                             const db = await createDB();
                                             const inactive: DietValue = {...dietToEnd, active: 0};
                                             await putDiet(db, inactive);

                                             dietChanged(inactive);
                                         }

                                         doEnd();
                                     }}></DietSummary>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default DietHome;
