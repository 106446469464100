import React, { useState } from 'react';
import { DietValue, NewDietValue } from '../database';
import {formatDate} from "../utils";

interface FloatState {
    value: number;
    text: string;
    success: boolean;
}

function parseFloat(s: string, prev: FloatState): FloatState {
    if (s.trim() === '') {
        return {value: 0, text: s, success: true};
    }
    let result = Number.parseFloat(s);
    if (Number.isNaN(result)) {
        return {value: prev.value, text: s, success: false};
    }
    return {value: result, text: s, success: true};
}

function toFloatState(diet: DietValue|undefined, key: 'dailyProtein'|'mealProtein'): FloatState {
    if (diet === undefined) {
        return {value: 0, text: '', success: true};
    }
    const value = diet[key];
    return {value: value, text: '' + value, success: true};
}

function Diet(props: {diet: DietValue|undefined, onDietSubmit: (diet: NewDietValue) => void}) {
    const [dailyProtein, setDailyProtein] = useState<FloatState>(toFloatState(props.diet, 'dailyProtein'));
    const [mealProtein, setMealProtein] = useState<FloatState>(toFloatState(props.diet, 'mealProtein'));
  
    function numberOfMeals() {
      if (mealProtein.value === 0) {
        return 1;
      }
      return Math.floor(dailyProtein.value / mealProtein.value);
    }
  
    return (
      <div className="Diet">
          <form
                onSubmit={(event) => {
                event.preventDefault();
                if (!dailyProtein.success || !mealProtein.success) {
                    return;
                }
                const start = new Date();
                    let startDate = formatDate(start);
                    props.onDietSubmit({
                    active: 1,
                    date: startDate,
                    dailyProtein: dailyProtein.value,
                    mealProtein: mealProtein.value,
                });
            }}>
            <p><label htmlFor="dailyProtein">Protein Per Day (g): </label>
            <input type="text" name="dailyProtein" id="dailyProtein"
                   value={dailyProtein.text}
                   onChange={(event) => setDailyProtein(parseFloat(event.target.value, dailyProtein))}/></p>
            <p><label htmlFor="mealProtein">Protein Per Meal (g): </label>
            <input type="text" name="mealProtein" id="mealProtein"
                   value={mealProtein.text}
                   onChange={(event) => setMealProtein(parseFloat(event.target.value, mealProtein))}/></p>
            <input type="submit" 
                disabled={!dailyProtein.success || !mealProtein.success}></input>
          </form>
          <p>{numberOfMeals()} meal(s) a day.</p>
          <a
            className="App-link"
            href="http://rapidfatlosshandbook.com/calculator/index.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Diet Calculator
          </a>
      </div>
    );
  }
  
  export default Diet;
  