import React, {useEffect, useState} from 'react';
import {
    addMealPlan,
    createDB,
    DietValue,
    FoodAmountValue,
    getActiveDiet,
    getMealPlan,
    PlanValue,
    putMealPlan
} from './database';
import './App.css';
import Planner from "./pages/Planner";
import {formatDate, numberOfMeals, range} from "./utils";
import {IonApp, IonRouterOutlet, setupIonicReact} from "@ionic/react";
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/core.css';

import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import './theme/variables.css';
import {IonReactRouter} from "@ionic/react-router";
import {Redirect, Route} from 'react-router-dom';
import Home from "./pages/Home";
import DietHome from "./pages/DietHome";

interface ActiveDietState {
    currentDate: Date;
    meal: number;
    diet: DietValue;
}

setupIonicReact();

function App() {
    const [diet, setDiet] = useState<ActiveDietState | undefined>();
    const [plan, setPlan] = useState<PlanValue | undefined>();

    function dietChanged(diet: DietValue) {
        if (!diet.active) {
            setDiet(undefined);
            return;
        }
        const year = Number.parseInt(diet.date.substring(0, 4), 10);
        const month = Number.parseInt(diet.date.substring(4, 6), 10) - 1;
        const date = Number.parseInt(diet.date.substring(6, 8), 10);
        const currentDate = new Date(year, month, date);
        setDiet({diet, currentDate, meal: 0});
    }

    function mealChanged(meal: number) {
        if (!diet) {
            return;
        }
        setDiet({...diet, meal: meal});
    }

    function amountsChanged(amounts: FoodAmountValue[]) {
        debugger;
        if (!plan) {
            return;
        }

        const newPlan = {...plan, amounts};

        async function doUpdate() {
            const db = await createDB();
            await putMealPlan(db, newPlan);

            setPlan(newPlan);
        }

        doUpdate();
    }

    useEffect(() => {
        async function loadDiet() {
            if (diet !== undefined) {
                return;
            }

            const db = await createDB();
            const activeDiet = await getActiveDiet(db);
            if (activeDiet) {
                dietChanged(activeDiet);
            } else {
                setDiet(undefined);
            }
        }

        loadDiet();
    }, [diet]);

    useEffect(() => {
        async function loadPlan() {
            if (diet === undefined) {
                setPlan(undefined);
                return;
            }

            const db = await createDB();
            let plan = await getMealPlan(db, diet.diet.id, formatDate(diet.currentDate), diet.meal);
            if (!plan) {
                plan = await addMealPlan(db, {
                    dietId: diet.diet.id,
                    date: formatDate(diet.currentDate),
                    meal: diet.meal,
                    amounts: []
                })
            }
            setPlan(plan);
        }

        loadPlan();
    }, [diet]);

    return (
        <div className="App">
            <header className="App-header">
                {diet && <div>
                    <p>Start: {diet.currentDate.toLocaleDateString()}</p>
                    <p>Meal: <select onChange={(event) => mealChanged(+event.target.value)} value={diet.meal}>
                        {range(numberOfMeals(diet.diet)).map((meal) =>
                            <option key={meal} value={meal}>{meal}</option>)}
                    </select></p>
                </div>}
            </header>
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path="/home">
                            <Home/>
                        </Route>
                        <Route exact path="/diet">
                            <DietHome
                                currentDate={diet?.currentDate}
                                diet={diet?.diet}
                                dietChanged={dietChanged}/>
                        </Route>
                        <Route exact path="/planner">
                            {diet && plan &&
                                <Planner mealProtein={diet.diet.mealProtein}
                                         amounts={plan}
                                         onChangeAmounts={newAmounts => amountsChanged(newAmounts)}/>
                            }
                        </Route>
                        <Route exact path="/">
                            <Redirect to="/home"/>
                        </Route>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        </div>
    )
        ;
}

export default App;
