/*
Cheese:

5.1 oz

Beef:

4.4 oz

Chicken:

4.4 oz

Fish:

5.1 oz

Egg Whites:

10.1 Egg Whites

Fat Free Cheese:

4.4 oz
 */

/**
 * All units are grams.
 */
export interface Food {
    name: string
    servingSize: number;
    unitsPerServing?: number;
    protein: number;
    carbohydrates: number;
    fat: number;
    emoji?: string;
}

export function getFood(name: string): Food|undefined {
    return foods.find((food) => food.name === name);
}

export function protein(name: string, size: number): number {
    const food = getFood(name);
    if (!food) {
        return 0;
    }
    return (size / food.servingSize) * food.protein
}

export function carbohydrates(name: string, size: number) {
    const food = getFood(name);
    if (!food) {
        return 0;
    }
    return (size / food.servingSize) * food.carbohydrates
}

export function fat(name: string, size: number) {
    const food = getFood(name);
    if (!food) {
        return 0;
    }
    return (size / food.servingSize) * food.fat
}

export const foods: ReadonlyArray<Food> = [
    {
        name: "Turkey Breast",
        servingSize: 56,
        protein: 12,
        carbohydrates: 0,
        fat: 0.5,
    }, {
        name: "Chicken Breast",
        emoji: "🍗",
        servingSize: 100,
        protein: 22.5,
        carbohydrates: 0,
        fat: 2.62,
    }, {
        name: "Egg Whites",
        emoji: "🥚",
        servingSize: 100,
        protein: 10.9,
        carbohydrates: 0.73,
        fat: 0.17,
    }, {
        name: "Whole Egg",
        servingSize: 50,
        unitsPerServing: 1,
        protein: 6,
        carbohydrates: 0.6,
        fat: 5,
    }, {
        name: "Greek Yogurt",
        servingSize: 150,
        protein: 16,
        carbohydrates: 5,
        fat: 0,
    }
]