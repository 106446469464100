import {FoodAmountValue} from "../database";
import React from "react";
import {IonButton, IonChip, IonIcon, IonLabel} from "@ionic/react";
import {closeCircle} from 'ionicons/icons';
import {carbohydrates, fat, protein} from "../foods";

function PlannerItem(props: { amount: FoodAmountValue, onFillUp: () => void, onRemove: () => void }) {
    const {amount, onFillUp, onRemove} = props;

    return (
        <div>
            <div>{amount.name}</div>
            <div>
                <IonButton onClick={() => onFillUp()}>Fill up</IonButton>
                <IonChip>
                    <IonIcon icon={closeCircle} onClick={() => onRemove()}></IonIcon>
                    <IonLabel>{amount.size.toFixed(1)}g</IonLabel>
                </IonChip>
            </div>
            <div>
                <IonChip><IonLabel>P: {protein(amount.name, amount.size).toFixed(1)}g</IonLabel></IonChip>
                <IonChip><IonLabel>C: {carbohydrates(amount.name, amount.size).toFixed(1)}g</IonLabel></IonChip>
                <IonChip><IonLabel>F: {fat(amount.name, amount.size).toFixed(1)}g</IonLabel></IonChip>
            </div>
        </div>
    );
}

export default PlannerItem;
