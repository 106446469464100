import {DietValue} from "./database";

const rangeCache: Array<number[]> = []

export function range(end: number): number[] {
    if (rangeCache[end]) {
        return rangeCache[end];
    }
    const result = [];
    for (let i = 0; i < end; i++) {
        result.push(i);
    }
    rangeCache[end] = result;
    return result;
}

export function numberOfMeals({mealProtein, dailyProtein}: DietValue) {
    if (mealProtein === 0) {
        return 1;
    }
    return Math.floor(dailyProtein / mealProtein);
}

export function formatDate(start: Date) {
    let startDate = `${start.getFullYear()}`;
    if (start.getMonth() + 1 < 10) {
        startDate += '0';
    }
    startDate += (start.getMonth() + 1);
    if (start.getDate() < 10) {
        startDate += '0';
    }
    startDate += start.getDate();
    return startDate;
}