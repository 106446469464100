import {DBSchema, IDBPDatabase, openDB} from 'idb';

export interface NewDietValue {
    active: number;
    date: string;
    dailyProtein: number;
    mealProtein: number;
}

export interface DietValue extends NewDietValue {
    id: number;
}

export interface FoodAmountValue {
    name: string;
    size: number;
}

export interface PlanValue {
    dietId: number;
    date: string;
    meal: number;
    amounts: FoodAmountValue[];
}

interface DietsDB extends DBSchema {
    diets: {
        key: number;
        value: DietValue;
        indexes: { 'active': number };
    };
    plans: {
        key: [number, string, number];
        value: PlanValue;
    }
}

export async function createDB(): Promise<IDBPDatabase<DietsDB>> {
    return await openDB<DietsDB>('dietplan', 2, {
        upgrade(db, oldVersion) {
            // Switch over the oldVersion, *without breaks*, to allow the database to be incrementally upgraded.
            switch (oldVersion) {
                // @ts-ignore
                case 0:
                    // Create a store of objects
                    const dietStore = db.createObjectStore('diets', {
                        autoIncrement: true,
                        keyPath: 'id'
                    });
                    dietStore.createIndex('active', 'active');
                    /* falls through */
                case 1:
                    db.createObjectStore('plans', {
                        keyPath: ['dietId', 'date', 'meal']
                    });

            }
        }
    });
}

export async function getActiveDiet(db: IDBPDatabase<DietsDB>): Promise<DietValue | undefined> {
    return db.getFromIndex('diets', 'active', 1);
}

export async function addDiet(db: IDBPDatabase<DietsDB>, diet: NewDietValue): Promise<DietValue> {
    const key = await db.add('diets', diet as DietValue);
    return {...diet, id: key};
}

export async function putDiet(db: IDBPDatabase<DietsDB>, diet: DietValue): Promise<DietValue> {
    await db.put('diets', diet);
    return diet;
}

export async function getMealPlan(db: IDBPDatabase<DietsDB>,
                                  dietId: number, date: string, meal: number): Promise<PlanValue | undefined> {
    return db.get('plans', [dietId, date, meal]);
}

export async function getDailyMealPlans(db: IDBPDatabase<DietsDB>,
                                        dietId: number, date: string): Promise<PlanValue[]> {
    return db.getAll('plans', IDBKeyRange.lowerBound([dietId, date, 0]));
}

export async function addMealPlan(db: IDBPDatabase<DietsDB>,
                                  plan: PlanValue): Promise<PlanValue> {
    await db.add('plans', plan);
    return plan;
}

export async function putMealPlan(db: IDBPDatabase<DietsDB>,
                                  plan: PlanValue): Promise<PlanValue> {
    await db.put('plans', plan);
    return plan;
}