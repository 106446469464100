import React from "react";
import {Food, foods, protein} from "../foods";
import {FoodAmountValue, PlanValue} from "../database";
import {IonButton, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import PlannerItem from "../components/PlannerItem";

// Food planner component.
function Planner(props: {
    mealProtein: number,
    amounts: PlanValue,
    onChangeAmounts: (amounts: FoodAmountValue[]) => void
}) {
    const {amounts, onChangeAmounts} = props;

    function addFood(food: Food) {
        if (amounts.amounts.some((amount) => food.name === amount.name)) {
            return;
        }

        onChangeAmounts([...amounts.amounts, {name: food.name, size: food.servingSize}]);
    }

    function removeFood(amount: FoodAmountValue) {
        const updatedAmounts = [...amounts.amounts];
        const index = updatedAmounts.indexOf(amount);
        updatedAmounts.splice(index, 1);
        onChangeAmounts(updatedAmounts);
    }

    function fillUp(amount: FoodAmountValue) {
        const goal = remainingProtein()
        const food = foods.find((food) => food.name === amount.name);
        if (!food) {
            return;
        }
        const addedSize = (goal / food.protein) * food.servingSize;

        if (addedSize > 0) {
            changeSize(amount, amount.size + addedSize);
        }
    }

    function changeSize(amount: FoodAmountValue, newSize: string | number) {
        const parsed = typeof newSize === 'number' ? newSize : Number.parseFloat(newSize);
        if (Number.isNaN(parsed)) {
            return;
        }
        if (parsed < 0) {
            return;
        }
        const updatedAmounts = [...amounts.amounts];
        const index = updatedAmounts.indexOf(amount);
        updatedAmounts[index] = {...amount, size: parsed};
        onChangeAmounts(updatedAmounts);
    }

    // function changeProtein(amount: FoodAmountValue, desiredAmount: string | number) {
    //     const parsed = typeof desiredAmount === 'number' ? desiredAmount : Number.parseFloat(desiredAmount);
    //     if (Number.isNaN(parsed)) {
    //         return;
    //     }
    //     if (parsed < 0) {
    //         return;
    //     }
    //     const updatedAmounts = [...amounts.amounts];
    //     const index = updatedAmounts.indexOf(amount);
    //     const food = getFood(updatedAmounts[index].name);
    //     if (!food) {
    //         return;
    //     }
    //     const newSize = (parsed / food.protein) * food.servingSize;
    //     updatedAmounts[index] = {...amount, size: newSize};
    //     onChangeAmounts(updatedAmounts);
    // }

    function remainingProtein() {
        return props.mealProtein - amounts.amounts.map(
            amount => protein(amount.name, amount.size)).reduce((a, b) => a + b, 0);
    }

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Planner</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">Planner</IonTitle>
                </IonToolbar>
            </IonHeader>
            <div>
                <h1>Planner</h1>
                <p>Remaining protein: {remainingProtein()}</p>
                <IonList inset={true}>
                    {amounts.amounts.map(amount =>
                        <IonItem>
                            <PlannerItem amount={amount}
                                         onFillUp={() => fillUp(amount)}
                                         onRemove={() => removeFood(amount)}/>
                        </IonItem>
                    )}
                </IonList>
                <ul>
                    {foods.map(food =>
                        <li key={food.name}>
                            {food.name}
                            <IonButton onClick={() => addFood(food)}>+</IonButton>
                        </li>)}
                </ul>
            </div>
        </IonContent></IonPage>
}

export default Planner;