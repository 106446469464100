import React from 'react';
import {DietValue} from '../database';
import {numberOfMeals} from "../utils";
import {IonButton} from "@ionic/react";

function DietSummary(props: { diet: DietValue, date: Date, onEndDiet: (diet: DietValue) => void }) {
    const {diet} = props;
    const {mealProtein, dailyProtein} = diet;

    return (
        <div className="Diet">
            <p>Protein Per Day: {dailyProtein} g</p>
            <p>Protein Per Meal: {mealProtein} g</p>
            <p>{numberOfMeals(diet)} meal(s) a day.</p>
            <form onSubmit={(event) => {
                event?.preventDefault();
                props.onEndDiet(props.diet);
            }}>
                <IonButton type="submit">End Diet</IonButton>
            </form>
        </div>
    );
}

export default DietSummary;
  